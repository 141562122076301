export enum SearchValueEnum {
  PROVINCES = "provinces",
  DISTRICTS = "districts",
  WARDS = "wards",
}

export const queryKeySearch = "searches";

export const searchKeyParam = "search";

export const typeKeyParam = "type";

export const priceStartKeyParam = "priceStart";

export const priceEndKeyParams = "priceEnd";

export const acreageStartKeyParam = "acreageStart";

export const acreageEndKeyParam = "acreageEnd";

export const provinceCodeKeyParam = "provinceCode";

export const districtCodeKeyParam = "districtCode";

export const wardCodeKeyParam = "wardCode";

export const pageKeyParam = "page";

export const limitKeyParam = "limit";
