"use client";

import { memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Image from "@/components/atoms/Image";
import { TitleHome } from "../atoms/TitleHome";
import { useTranslation } from "react-i18next";
const HomeOutstanding = memo(function HomeOutstanding() {
  const {
    t
  } = useTranslation();
  const data = [{
    title: "Tổng quan Imperial Oasis Quy Nhơn",
    image: "https://media.tapchitaichinh.vn/w1480/images/upload/2022/12/30/thi-truong-bat-dong-san-2023.jpg",
    squareMetre: "8,9 ha",
    address: "Phù Cát, Bình Định"
  }, {
    title: "The Priva",
    image: "https://nld.mediacdn.vn/291774122806476800/2023/2/18/bat-dong-san-ha-noi-1-1636107304439879140202-16710210755351058304041-167671483264338352530.jpeg",
    squareMetre: "1.84 ha",
    address: "Bình Tân, Hồ Chí Mình"
  }, {
    title: "Thảo Điền Green",
    image: "https://media.vneconomy.vn/w800/images/upload/2022/10/13/screen-shot-2022-10-13-at-8-47-35-am.png",
    squareMetre: "4.967 m²",
    address: "Quận 2, Hồ Chí Minh"
  }];
  return <>
      <TitleHome>{t("home.Outstanding project")}</TitleHome>
      <div className="mt-4">
        <Swiper modules={[Navigation]} spaceBetween={30} slidesPerView={3} speed={500} navigation={{
        enabled: true
      }} breakpoints={{
        1024: {
          slidesPerView: 3,
          loop: true,
          navigation: {
            enabled: false
          }
        },
        600: {
          slidesPerView: 2,
          spaceBetween: 20,
          navigation: {
            enabled: false
          }
        },
        480: {
          slidesPerView: 1.5,
          spaceBetween: 10,
          navigation: {
            enabled: false
          }
        }
      }}>
          {data.map((item, index) => <SwiperSlide key={index}>
              <div className="relative h-72 rounded-md bg-white shadow-md">
                <div className="aspect-h-7 aspect-w-10 group block h-48 w-full overflow-hidden rounded-t-md bg-gray-100 focus-within:ring-2 focus-within:ring-violet-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                  <Image src={item.image} alt={item.image} className="aspect-[16/9] h-full w-full rounded-t-md bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                  <button type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">View details</span>
                  </button>
                </div>
                <div className="flex flex-col space-y-1 p-4">
                  <p className="pointer-events-none line-clamp-1 text-base font-semibold text-gray-900">
                    {item.title}
                  </p>
                  <p className="pointer-events-none block text-sm">
                    {item.squareMetre}
                  </p>
                  <p className="inline-flex items-center justify-start text-sm text-gray-500">
                    {item.address}
                  </p>
                </div>
              </div>
            </SwiperSlide>)}
        </Swiper>
      </div>
    </>;
});
export default HomeOutstanding;