import type { FC } from "react";
type Props = {
  className: string;
};
const SunIcon: FC<Props> = ({
  className
}) => {
  return <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M17.6937 9.54053C10.7279 9.54053 5.08098 15.1874 5.08098 22.1532H30.3064C30.3064 15.1874 24.6595 9.54053 17.6937 9.54053Z" fill="#FCB40A" />
      <path d="M33.1893 21.7928L2.91881 21.7928" stroke="#FCB40A" strokeWidth="1.7" strokeLinecap="round" />
      <path d="M30.3071 25.3963L6.52314 25.3963" stroke="#FFCE5B" strokeWidth="1.7" strokeLinecap="round" />
      <path d="M23.0991 29.0001L13.7297 29.0001" stroke="#FFCE5B" strokeWidth="1.7" strokeLinecap="round" />
      <path d="M27.4235 5.21619L24.5406 8.81981" stroke="#FFCE5B" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.24351 5.21619L10.1264 8.81981" stroke="#FFCE5B" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.054 2.33319V7.0179" stroke="#FFCE5B" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.19 11.7027L30.3071 13.1441" stroke="#FFCE5B" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.47736 11.7027L5.08098 13.1441" stroke="#FFCE5B" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
    </svg>;
};
export default SunIcon;