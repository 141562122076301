import type { FC } from "react";
type Props = {
  className: string;
};
const CalculatorIcon: FC<Props> = ({
  className
}) => {
  return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M22.5824 40.6882H6C4.89543 40.6882 4 39.7928 4 38.6882V24.9647M40.6882 22.1059V9.24118M40.6882 9.24118V6C40.6882 4.89543 39.7928 4 38.6882 4H6C4.89543 4 4 4.89543 4 6V9.24118M40.6882 9.24118H22.3441M4 9.24118H22.3441M4 9.24118V24.9647M22.3441 9.24118V24.9647H4M13.5294 13.0529V19.7235M17.3412 16.3882H9.71765M27.8235 16.3882H35.4471M10.6706 29.2529L16.3882 34.9706M16.3882 29.2529L10.6706 34.9706" stroke="#9F82B1" strokeWidth="2" strokeLinecap="round" />
      <circle cx="34" cy="34" r="11" fill="#845D9C" />
      <path d="M33.064 40.16C32.3813 40.16 31.7787 39.9947 31.256 39.664C30.744 39.3334 30.344 38.864 30.056 38.256C29.768 37.6374 29.624 36.912 29.624 36.08C29.624 35.2374 29.768 34.5174 30.056 33.92C30.344 33.312 30.744 32.8427 31.256 32.512C31.7787 32.1814 32.3813 32.016 33.064 32.016C33.6187 32.016 34.12 32.1387 34.568 32.384C35.016 32.6294 35.352 32.9547 35.576 33.36V31.296H33.224C32.7227 31.296 32.472 31.0614 32.472 30.592C32.472 30.1227 32.7227 29.888 33.224 29.888H35.576V29.44C35.576 29.1947 35.6613 28.992 35.832 28.832C36.0027 28.672 36.248 28.592 36.568 28.592C36.888 28.592 37.1333 28.672 37.304 28.832C37.4853 28.992 37.576 29.1947 37.576 29.44V29.888H38.312C38.8027 29.888 39.048 30.1227 39.048 30.592C39.048 31.0614 38.8027 31.296 38.312 31.296H37.576V39.104C37.576 39.7867 37.2453 40.128 36.584 40.128C35.9227 40.128 35.592 39.7867 35.592 39.104V38.768C35.368 39.1947 35.032 39.536 34.584 39.792C34.1467 40.0374 33.64 40.16 33.064 40.16ZM33.624 38.64C34.2213 38.64 34.7013 38.4267 35.064 38C35.4267 37.5627 35.608 36.9227 35.608 36.08C35.608 35.2267 35.4267 34.592 35.064 34.176C34.7013 33.7494 34.2213 33.536 33.624 33.536C33.0267 33.536 32.5467 33.7494 32.184 34.176C31.8213 34.592 31.64 35.2267 31.64 36.08C31.64 36.9227 31.8213 37.5627 32.184 38C32.5467 38.4267 33.0267 38.64 33.624 38.64Z" fill="white" />
    </svg>;
};
export default CalculatorIcon;