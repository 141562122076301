"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Image from "next/image";
import "swiper/css";
export default function Banner() {
  const src = [{
    id: 1,
    src: "/images/banner/19168732.jpg"
  }, {
    id: 2,
    src: "/images/banner/19168733.jpg"
  }, {
    id: 3,
    src: "/images/banner/19168734.jpg"
  }, {
    id: 4,
    src: "/images/banner/19168735.jpg"
  }];
  return <Swiper modules={[Autoplay]} spaceBetween={0} slidesPerView={1} autoplay={{
    delay: 3000
  }} loop={true}>
      {src.map(item => <SwiperSlide key={item.id}>
          <div className="h-[6.25rem] w-full sm:h-[37.5rem]">
            <Image src={item.src} alt={`Banner ${item.id}`} className="h-full w-full object-cover" width={500} height={500} />
          </div>
        </SwiperSlide>)}
    </Swiper>;
}