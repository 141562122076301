"use client";

import type { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

// import Button from '@/components/atoms/Button'
import Title from "@/components/atoms/Title";
const CustomizeErrorBoundary = ({
  children
}: {
  children: ReactNode;
}) => {
  const {
    t
  } = useTranslation();
  return <ErrorBoundary onReset={details => {
    console.log("details: ", details);
    // Reset the state of your app so the error doesn't happen again
  }}
  // onError={(error) => {
  //   console.error(error)
  // }}
  fallbackRender={({}) => <div className="flex flex-col items-center justify-center py-20">
          <Title>{t("error.There are currently no data")}</Title>
          {/* <Button type="button" className="mt-10" onClick={() => resetErrorBoundary()}>
            {t('common.Try again')}
           </Button> */}
        </div>}>
      {children}
    </ErrorBoundary>;
};
export default CustomizeErrorBoundary;