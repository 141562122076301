import type { FC, ReactNode } from "react";
type Props = {
  children: ReactNode;
  className?: string;
};
const Title: FC<Props> = ({
  children,
  className
}) => {
  return <h2 className={`${className} min-w-0 flex-1 break-words break-all text-base font-medium text-gray-900`}>
      {children}
    </h2>;
};
export default Title;