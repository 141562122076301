"use client";

import { memo } from "react";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { provinceCodeKeyParam } from "@/types/search";
import { NavigateRouter } from "@/utils";
import { CitiesHotType } from "@/types";
import { TitleHome } from "../atoms/TitleHome";
import Image from "next/image";
type Props = {
  postCityHot: CitiesHotType[] | null;
};
const HomeListImageLocation = memo(function HomeListImageLocation({
  postCityHot
}: Props) {
  const {
    t
  } = useTranslation();
  return <>
      <TitleHome>{t("home.Real estate by location")}</TitleHome>
      <div className="mt-4">
        {postCityHot && postCityHot?.length > 0 ? <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
            {postCityHot?.find(city => city.code === 79) && <div className="aspect-h-1 aspect-w-2 sm:aspect-h-1 sm:aspect-w-1 group relative h-64 overflow-hidden rounded-md sm:row-span-2 lg:h-full">
                <Image src="https://cdn-i.vtcnews.vn/upload/2021/07/14/sai-gon-duong-nguyen-17160664.jpg" width={500} height={500} alt={postCityHot?.find(city => city.code === 79)?.name ?? "city-host"} className="aspect-[16/9] h-full w-full rounded-md bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0" />
                <div className="absolute inset-0 flex items-start p-6">
                  <div>
                    <h3 className="font-semibold text-white">
                      <Link href={NavigateRouter.REAL_ESTATE_FOR_SALE + `?${provinceCodeKeyParam}=79`}>
                        <span className="absolute inset-0" />
                        {postCityHot?.find(city => city.code === 79)?.name}
                      </Link>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      {postCityHot?.find(city => city.code === 79)?.total_post_estate}{" "}
                      tin đăng
                    </p>
                  </div>
                </div>
              </div>}

            <div className="grid grid-cols-2 gap-x-6">
              <div className="sm:aspect-none group relative h-28 overflow-hidden rounded-md lg:h-full">
                <Image src={"https://www.vietfuntravel.com.vn/image/data/Ha-Noi/pho-co-ha-noi/Hinh-anh-pho-co-Ha-Noi-ve-dem-4.png"} width={500} height={500} alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters." className="aspect-[16/9] h-full w-full rounded-md bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                <div aria-hidden="true" className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                <div className="absolute inset-0 flex items-start p-6">
                  <div>
                    <h3 className="font-semibold text-white">
                      <Link href={NavigateRouter.REAL_ESTATE_FOR_SALE + `?${provinceCodeKeyParam}=1`}>
                        <span className="absolute inset-0" />
                        {postCityHot?.find(city => city.code === 1)?.name}
                      </Link>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      {postCityHot?.find(city => city.code === 1)?.total_post_estate}{" "}
                      tin đăng
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm:aspect-none group relative h-28 overflow-hidden rounded-md lg:h-full">
                <Image src={"https://hodadi.s3.amazonaws.com/production/blogs/pictures/000/000/028/original/du-lich-da-nang.jpg?1501897690"} width={500} height={500} alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters." className="aspect-[16/9] h-full w-full rounded-md bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                <div aria-hidden="true" className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                <div className="absolute inset-0 flex items-start p-6">
                  <div>
                    <h3 className="font-semibold text-white">
                      <Link href={NavigateRouter.REAL_ESTATE_FOR_SALE + `?${provinceCodeKeyParam}=48`}>
                        <span className="absolute inset-0" />
                        {postCityHot?.find(city => city.code === 48)?.name}
                      </Link>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      {postCityHot?.find(city => city.code === 48)?.total_post_estate}{" "}
                      tin đăng
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-6">
              <div className="sm:aspect-none group relative h-28 overflow-hidden rounded-md lg:h-full">
                <Image src={"https://phongnhaexplorer.com/wp-content/uploads/2021/08/thanh-pho-tre-binh-duong.jpg"} width={500} height={500} alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters." className="aspect-[16/9] h-full w-full rounded-md bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                <div aria-hidden="true" className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                <div className="absolute inset-0 flex items-start p-6">
                  <div>
                    <h3 className="font-semibold text-white">
                      <Link href={NavigateRouter.REAL_ESTATE_FOR_SALE + `?${provinceCodeKeyParam}=74`}>
                        <span className="absolute inset-0" />
                        {postCityHot?.find(city => city.code === 74)?.name}
                      </Link>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      {postCityHot?.find(city => city.code === 74)?.total_post_estate}{" "}
                      tin đăng
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm:aspect-none group relative h-28 overflow-hidden rounded-md lg:h-full">
                <Image src={"https://top10bienhoa.com/wp-content/uploads/2019/06/bien-hoa-rach-gia-2.jpg"} width={500} height={500} alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters." className="aspect-[16/9] h-full w-full rounded-md bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                <div aria-hidden="true" className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                <div className="absolute inset-0 flex items-start p-6">
                  <div>
                    <h3 className="font-semibold text-white">
                      <Link href={NavigateRouter.REAL_ESTATE_FOR_SALE + `?${provinceCodeKeyParam}=75`}>
                        <span className="absolute inset-0" />
                        {postCityHot?.find(city => city.code === 75)?.name}
                      </Link>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      {postCityHot?.find(city => city.code === 75)?.total_post_estate}{" "}
                      tin đăng
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> : <div className="text-center">
            <p className="text-lg font-medium text-gray-700">
              {t("seller.There are currently no postings.")}
            </p>
          </div>}
      </div>
    </>;
});
export default HomeListImageLocation;