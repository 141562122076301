import { memo } from "react";
import React from "react";
import { ClockIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import type { BlogType } from "@/types";
import { LogoNews } from "@/components/atoms/LogoNews";
import { NavigateRouter, stringDatetimeFormat } from "@/utils";
import Image from "next/image";
import { pathImageNotAvailable } from "@/common";
type Props = {
  activeTab: string;
  news?: BlogType[] | null;
};
const ListNews = memo(function ListNews({
  activeTab,
  news
}: Props) {
  const {
    t
  } = useTranslation();
  return <>
      {activeTab === "hot" && <>
          <div className="sm:hidden">
            <ul className="divide-y divide-gray-200">
              {news && news.length > 0 ? <>
                  {news.map((item, index) => <li key={index}>
                      <Link className="flex py-4" target="_blank" href={news[0]?.link_active}>
                        <Image className="aspect-[16/9] h-20 w-20 rounded-md object-cover" src={item.link_image_content || pathImageNotAvailable} width={500} height={500} alt={item.title} />
                        <div className="ml-3 space-y-0.5">
                          <p className="line-clamp-2 text-sm font-medium text-gray-900 hover:text-violet-900 lg:line-clamp-none">
                            {item.title}
                          </p>
                          <p className="text-xs text-gray-500">
                            <time dateTime={item.published} className="mr-8">
                              {stringDatetimeFormat(item.published)}
                            </time>
                          </p>
                          <div>
                            {item?.link_image_device ? <Image src={item.link_image_content || pathImageNotAvailable} width={500} height={500} alt={item?.title} className="aspect-auto h-4 w-10 flex-none bg-white/10 sm:w-auto" /> : <LogoNews className="aspect-auto h-4 w-10 sm:w-auto" />}
                          </div>
                        </div>
                      </Link>
                    </li>)}
                  <li>
                    <div className="mt-4 text-center hover:text-yellow-bds lg:hidden">
                      <Link className="text-sm text-violet-bds" href={NavigateRouter.NEWS}>
                        {t("common.See more")}
                      </Link>
                    </div>
                  </li>
                </> : <div className="text-center">
                  <p className="text-lg font-medium text-gray-700">
                    {t("news.There are currently no news")}
                  </p>
                </div>}
            </ul>
          </div>
          <div className="hidden sm:block">
            {news && news.length > 0 ? <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 sm:col-span-7">
                  <article key={news[0].id} className="flex flex-col items-start justify-between">
                    <div className="relative w-full">
                      <Link target="_blank" href={news[0].link_active}>
                        <Image src={news[0].link_image_content || pathImageNotAvailable} width={500} height={500} alt={news[0].link_image_content} className="aspect-[16/9] w-full rounded-md bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                        <div className="absolute inset-0 rounded-md ring-1 ring-inset ring-gray-900/10" />
                      </Link>
                    </div>
                    <div className="max-w-xl">
                      <div className="group relative">
                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                          <Link target="_blank" href={news[0].link_active}>
                            <span className="absolute inset-0" />
                            {news[0].title}
                          </Link>
                        </h3>
                        <p className="mt-2 line-clamp-3 text-sm leading-6 text-gray-600">
                          {news[0].description}
                        </p>
                      </div>
                      <div className="relative mt-2 flex items-center gap-x-4">
                        {news[0].link_image_device ? <Image src={news[0].link_image_device || pathImageNotAvailable} width={500} height={500} alt={news[0].link_image_device} className="aspect-auto h-4 w-10 flex-none bg-white/10 sm:w-auto" /> : <LogoNews className="aspect-auto h-4 w-10 sm:w-auto" />}
                        <div className="text-sm leading-6">
                          <p className="pointer-events-none inline-flex items-center justify-center text-sm text-gray-500">
                            <ClockIcon className="mr-1 h-4 w-4" />{" "}
                            <time dateTime={news[0]?.published} className="mr-8">
                              {stringDatetimeFormat(news[0]?.published ?? "")}
                            </time>
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="col-span-5">
                  <ul className="divide-y divide-gray-200">
                    {news.map((item, index) => index > 0 && index < 8 && <li key={index} className="py-4">
                            <Link className="flex flex-col gap-2" href={item.link_active} target="_blank">
                              <p className="line-clamp-1 text-gray-700 hover:text-violet-900">
                                {item.title}
                              </p>
                              <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-500">
                                <time dateTime={item.created_at} className="mr-8">
                                  {stringDatetimeFormat(item?.published)}
                                </time>
                                <div className="-ml-4 flex items-center gap-x-4">
                                  <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/5">
                                    <circle cx={1} cy={1} r={1} />
                                  </svg>
                                  <div className="flex gap-x-2.5">
                                    {item.link_image_device ? <Image src={item.link_image_device || pathImageNotAvailable} width={500} height={500} alt={item.link_image_device} className="aspect-auto h-4 w-10 flex-none bg-white/10 sm:w-auto" /> : <LogoNews className="aspect-auto h-4 w-10 sm:w-auto" />}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </li>)}
                  </ul>
                </div>
              </div> : <div className="text-center">
                <p className="text-sm font-medium text-gray-700">
                  {t("news.There are currently no news")}
                </p>
              </div>}
          </div>
        </>}
    </>;
});
export default ListNews;