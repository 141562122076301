"use client";

import { useState } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useTranslation } from "react-i18next";
import { NavigateRouter, classNames } from "@/utils";
import { BlogType } from "@/types";
import ListNews from "../molecules/ListNews";
type Props = {
  spotlight: BlogType[] | null;
};
const HomeNews = ({
  spotlight
}: Props) => {
  const {
    t
  } = useTranslation();
  const pathname = usePathname();
  const [activeTab, setActiveTab] = useState<string>("hot");
  const tabs = [{
    name: t("home.Hot news"),
    href: NavigateRouter.NEWS,
    onClick: setActiveTab,
    current: true
  }, {
    name: t("header.News"),
    href: NavigateRouter.NEWS,
    onClick: setActiveTab,
    current: false
  }];
  return <>
      <div>
        <div className="block">
          <div className="scrollbar overflow-hidden overflow-x-auto border-b border-gray-200 sm:max-w-[99%]">
            <nav className="-mb-px inline-flex space-x-4 sm:space-x-8" aria-label="Tabs">
              {tabs.map(tab => <Link key={tab.name} href={tab.href} className={classNames(pathname?.includes(tab.href) ? "border-yellow-bds text-violet-bds" : "border-transparent text-gray-500 hover:border-yellow-bds hover:text-violet-bds", "whitespace-nowrap border-b-2 px-1 py-4 text-base font-bold")} aria-current={tab.current ? "page" : undefined}>
                  {tab.name}
                </Link>)}
              <Link href={NavigateRouter.GOLD_PRICE} className="whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-base font-bold text-yellow-bds hover:border-yellow-bds hover:text-violet-bds">
                {t("news.Gold price today")}
              </Link>
            </nav>
          </div>
        </div>
      </div>
      <div className="py-4">
        <ListNews activeTab={activeTab} news={spotlight} />
      </div>
    </>;
};
export default HomeNews;