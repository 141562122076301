"use client";

import { useTranslation } from "react-i18next";
import ForSaleIcon from "../atoms/ForSaleIcon";
import ForRentIcon from "../atoms/ForRentIcon";
import ProjectIcon from "../atoms/ProjectIcon";
import WikiIcon from "../atoms/WikiIcon";
export default function HomeDescription() {
  const {
    t
  } = useTranslation();
  return <div className="grid grid-cols-1 gap-8 text-center sm:grid-cols-4 sm:gap-16">
      <div className="flex flex-col items-center gap-4">
        <ForSaleIcon className="h-20 w-20" />
        <p className="text-sm font-semibold text-gray-900 lg:text-base">
          {t("home.Real estate for sale")}
        </p>
        <p className="text-sm text-gray-700">
          Bạn có thể tìm thấy ngôi nhà mơ ước hoặc cơ hội đầu tư hấp dẫn thông
          qua lượng tin rao lớn, uy tín về các loại hình bất động sản bán tại
          Việt Nam, bao gồm bán nhà riêng, bán nhà mặt tiền, bán căn hộ chung
          cư, bán biệt thự, bán đất, bán shophouse và các loại hình BĐS khác.
        </p>
      </div>
      <div className="flex flex-col items-center gap-4">
        <ForRentIcon className="h-20 w-20" />
        <p className="text-sm font-semibold text-gray-900 lg:text-base">
          {t("home.Real estate for rent")}
        </p>
        <p className="text-sm text-gray-700">
          Cập nhật thường xuyên và đầy đủ các loại hình bất động sản cho thuê
          như: thuê phòng trọ, nhà riêng, thuê biệt thự, văn phòng, kho xưởng
          hay thuê mặt bằng kinh doanh giúp bạn nhanh chóng tìm được bất động
          sản ưng ý.
        </p>
      </div>
      <div className="flex flex-col items-center gap-4">
        <ProjectIcon className="h-20 w-20" />
        <p className="text-sm font-semibold text-gray-900 lg:text-base">
          {t("home.Project evaluation")}
        </p>
        <p className="text-sm text-gray-700">
          Các video đánh giá tổng quan dự án cung cấp góc nhìn khách quan của
          các chuyên gia về những dự án nổi bật tại Việt Nam, giúp bạn đưa ra
          quyết định đúng đắn cho nơi an cư lý tưởng hoặc cơ hội đầu tư sinh
          lời.
        </p>
      </div>
      <div className="flex flex-col items-center gap-4">
        <WikiIcon className="h-20 w-20" />
        <p className="text-sm font-semibold text-gray-900 lg:text-base">
          {t("home.Real Estate Wiki")}
        </p>
        <p className="text-sm text-gray-700">
          Ngoài cập nhật những biến động thị trường, chúng tôi còn cung cấp kiến
          ​​thức, kinh nghiệm về mua bán, cho thuê, đầu tư, vay mua nhà, phong
          thủy, thiết kế nhà, mọi thông tin cần thiết để dẫn lối người tìm nhà
          tìm thấy căn nhà mơ ước.
        </p>
      </div>
    </div>;
}