import type { FC } from "react";
type Props = {
  className: string;
};
const HouseIcon: FC<Props> = ({
  className
}) => {
  return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M12 12.7257V10.531C12 10.2375 12.3637 10 12.8133 10H29.1867C29.6363 10 30 10.2375 30 10.531V25.469C30 25.7625 29.6363 26 29.1867 26H19.3193" stroke="#FFD1BC" strokeWidth="1.7" />
      <path d="M26.6667 15.3333L22.6667 15.3333" stroke="#FF9869" strokeWidth="1.7" strokeLinecap="round" />
      <path d="M26.6667 18L24.6667 18L22.6667 18" stroke="#FF9869" strokeWidth="1.7" strokeLinecap="round" />
      <path d="M26.6667 20.6667L22.6667 20.6667" stroke="#FF9869" strokeWidth="1.7" strokeLinecap="round" />
      <rect x="1.33333" y="6" width="18" height="23.3333" rx="2" fill="white" stroke="#FF661F" strokeWidth="1.7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.8759 15.6643C15.5815 15.9932 15.0249 15.9933 14.7305 15.6643L14.485 15.4341V18.3777C14.485 18.8381 14.1251 19.1999 13.6668 19.1999H11.2123V16.7333C11.2123 16.2728 10.8523 15.911 10.3941 15.911C9.93585 15.911 9.57591 16.2728 9.57591 16.7333V19.1999H7.12136C6.66312 19.1999 6.30318 18.8381 6.30318 18.3777V15.4341L6.05767 15.6644C5.76334 15.9933 5.20671 15.9934 4.91227 15.6644C4.58489 15.3519 4.58489 14.8257 4.91227 14.5133L9.82136 9.57994C10.1324 9.2511 10.6557 9.2511 10.9668 9.57994L15.8759 14.5133C16.2032 14.8257 16.2032 15.3519 15.8759 15.6643Z" fill="#FF661F" />
      <path d="M16 22.6667L5.33333 22.6667" stroke="#FF661F" strokeWidth="1.7" strokeLinecap="round" />
      <path d="M13.3333 25.3333L8 25.3333" stroke="#FF661F" strokeWidth="1.7" strokeLinecap="round" />
    </svg>;
};
export default HouseIcon;