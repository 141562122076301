"use client";

import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Image from "@/components/atoms/Image";
import { ApiPaging, BlogType } from "@/types";
import { TitleHome } from "../atoms/TitleHome";
type Props = {
  blogs: ApiPaging<BlogType[]> | null;
};
const HomeEstateNews = memo(function HomeEstateNews({
  blogs
}: Props) {
  const {
    t
  } = useTranslation();
  return <>
      <TitleHome>{t("home.Real Estate News")}</TitleHome>
      <div className="mt-4">
        {blogs && blogs?.total_item_count > 0 ? <Swiper modules={[Navigation]} spaceBetween={30} slidesPerView={3} speed={500} navigation={{
        enabled: true
      }} breakpoints={{
        1024: {
          slidesPerView: 3,
          navigation: {
            enabled: false
          }
        },
        600: {
          slidesPerView: 2,
          spaceBetween: 20,
          navigation: {
            enabled: false
          }
        },
        480: {
          slidesPerView: 1.5,
          spaceBetween: 10,
          navigation: {
            enabled: false
          }
        }
      }}>
            {blogs?.items.map((item, index) => <SwiperSlide key={index}>
                <div className="relative h-full rounded-md bg-white shadow-md">
                  <div className="aspect-h-7 aspect-w-10 group block h-48 w-full overflow-hidden rounded-t-md bg-gray-100">
                    <Image src={item.link_image_content} alt={item.link_image_content} className="aspect-[16/9] h-full w-full rounded-t-md bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                    <a href={item.link_active} target="_blank" className="absolute inset-0 focus:outline-none">
                      <span className="sr-only">View details</span>
                    </a>
                  </div>
                  <div className="p-2 lg:p-4">
                    <div className="flex flex-row gap-x-4">
                      <p className="text-3xl font-bold text-gray-900 lg:leading-10">
                        {index + 1}
                      </p>
                      <p className="line-clamp-2 text-base font-semibold text-gray-900">
                        {item.title}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>)}
          </Swiper> : <div className="text-center">
            <p className="text-lg font-medium text-gray-700">
              {t("seller.There are currently no postings.")}
            </p>
          </div>}
      </div>
    </>;
});
export default HomeEstateNews;