import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/Banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/molecules/CustomizeErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/HomeDescription.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/HomeEstateNews.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/HomeListImageLocation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/HomeMenuDescription.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/HomeNews.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/HomeNewsForYou.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/HomeOutstanding.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/HomeUtilitySupport.tsx");
