"use client";

import { memo, useState } from "react";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { domainUrl } from "@/common";
const HomeMenuDescription = memo(function HomeMenuDescription() {
  const {
    t
  } = useTranslation();
  const [isLoadMore, setIsLoadMore] = useState(false);
  return <>
      <div className="">
        <p className="text-sm text-gray-700">
          <span className={`${!isLoadMore && "line-clamp-5"}`}>
            <Link className="text-violet-bds visited:text-violet-bds hover:text-violet-300" href={domainUrl}>
              bds369.com
            </Link>{" "}
            là trang web bất động sản hàng đầu tại Việt Nam - Nơi tốt nhất dành
            cho những người đang tìm kiếm bất động sản để ở hoặc đầu tư. Chúng
            tôi cung cấp dữ liệu tin rao lớn với đa dạng loại hình bất động sản
            giúp bạn có những lựa chọn phù hợp với nhu cầu của mình.
            <br />
            <br />
            Ở phân khúc nhà đất bán, các loại hình nổi bật gồm bán căn hộ chung
            cư, bán nhà riêng, nhà mặt tiền, biệt thự và liền kề, bán đất, đất
            nền dự án và một số loại hình đang được nhà đầu tư quan tâm như bán
            condotel, shophouse và khu nghỉ dưỡng. Ngoài ra, người dùng quan tâm
            đến bất động sản cho thuê có nhiều cơ hội để tìm thấy nhà đất ưng ý
            với danh sách tin rao được cập nhật liên tục tại các danh mục cho
            thuê nhà nguyên căn, thuê phòng trọ giá rẻ, thuê văn phòng, mặt bằng
            kinh doanh.
            <br />
            <br />
            Với bộ lọc chi tiết dựa theo khoảng giá, vị trí, diện tích,... bạn
            có thể dễ dàng chọn lọc bất động sản phù hợp trong hàng ngàn tin rao
            bán và cho thuê được cập nhật liên tục mỗi ngày. Lượng tin rao chính
            chủ lớn đáp ứng nhu cầu của những người tìm nhà không qua môi giới.
            <br />
            <br />
            <Link className="text-violet-bds visited:text-violet-bds hover:text-violet-300" href={domainUrl}>
              bds369.com
            </Link>{" "}
            cũng cung cấp thông tin toàn diện nhất về các dự án căn hộ chung cư,
            những đánh giá dự án từ góc nhìn chuyên gia giúp bạn ra quyết định
            đúng đắn. Ở chuyên mục Wiki BĐS có thể tìm thấy các thông tin đánh
            giá thị trường, những kiến thức, kinh nghiệm mua bán, cho thuê bất
            động sản để đồng hành cùng bạn trong hành trình tìm nhà.
            <br />
            <br />
            Truy cập{" "}
            <Link className="text-violet-bds visited:text-violet-bds hover:text-violet-300" href={domainUrl}>
              bds369.com
            </Link>{" "}
            để được cung cấp giải pháp hiệu quả trong lĩnh vực mua bán bất động
            sản cũng như cho thuê nhà đất tại Việt Nam.
          </span>
          <span className="inline-flex items-baseline">
            {isLoadMore ? <button className="text-violet-500" onClick={() => setIsLoadMore(false)}>
                {t("common.Collapse")}
              </button> : <button className="text-violet-500" onClick={() => setIsLoadMore(true)}>
                {t("common.See more")}
              </button>}
          </span>
        </p>
      </div>
    </>;
});
export default HomeMenuDescription;