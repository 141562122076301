"use client";

import type { FC, ReactNode } from "react";
import { memo } from "react";
import { HeartIcon, MapPinIcon } from "@heroicons/react/24/outline";
import dynamic from "next/dynamic";
import Link from "next/link";
import { DOMAIN_IMAGE } from "@/common";
import DateTimeNoSecs from "@/components/atoms/DateTimeNoSecs";
import Image from "@/components/atoms/Image";
import { EstateTypeEnum, type PostSellerType } from "@/types";
import { NavigateRouter, convertToInternationalCurrencySystem } from "@/utils";
const ButtonOutline = dynamic(() => import("@/components/atoms/ButtonOutline"));
type ListWrapProps = {
  id: string;
  children: ReactNode;
  type: number;
};
const ListWrap: FC<ListWrapProps> = ({
  id,
  children,
  type
}) => {
  return <>
      <Link href={EstateTypeEnum.SELL.includes(type.toString()) ? NavigateRouter.REAL_ESTATE_FOR_SALE_ID(id) : NavigateRouter.REAL_ESTATE_FOR_RENT_ID(id)}>
        {children}
      </Link>
    </>;
};
type Props = {
  post: PostSellerType;
  isDetail?: boolean;
};
const HomeNews: FC<Props> = memo(function HomeNews({
  post,
  isDetail = false
}) {
  return <ListWrap id={post.id} type={post.type}>
      <div className="relative h-full w-full rounded-md bg-white shadow-lg">
        <div className="aspect-h-7 aspect-w-10 group block h-48 w-full overflow-hidden rounded-t-md bg-gray-100 focus-within:ring-2 focus-within:ring-violet-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
          <Image src={DOMAIN_IMAGE + post.images.find(image => image.is_avatar)?.link} alt={post.title} className="aspect-[16/9] h-full w-full rounded-t-md bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
          <button type="button" className="absolute inset-0 focus:outline-none">
            <span className="sr-only">View details</span>
          </button>
        </div>
        <div className="flex flex-col space-y-1 p-4">
          <p className={`text-sm font-semibold text-gray-900 ${isDetail ? "line-clamp-1" : "line-clamp-2"}`}>
            {post.title}
          </p>
          <p className="pointer-events-none line-clamp-1 text-base text-red-500">
            {convertToInternationalCurrencySystem(post.price)} · {post.acreage}{" "}
            m²
          </p>
          <p className="inline-flex items-center justify-start text-xs text-gray-500">
            <MapPinIcon className="mr-1 h-4 w-4" />
            <span className="line-clamp-1">
              {post.district.name}, {post.province.name}
            </span>
          </p>
          <div className="z-10 flex items-center justify-between pt-4">
            <DateTimeNoSecs datetime={post.created_at} />
            <ButtonOutline>
              <HeartIcon className="h-5 w-5" />
            </ButtonOutline>
          </div>
        </div>
      </div>
    </ListWrap>;
});
export default HomeNews;