"use client";

import { memo } from "react";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import CalculatorIcon from "@/components/atoms/CalculatorIcon";
import HouseIcon from "@/components/atoms/HouseIcon";
import SunIcon from "@/components/atoms/SunIcon";
import YinYangIcon from "@/components/atoms/YinYangIcon";
import { TitleHome } from "../atoms/TitleHome";
const HomeUtilitySupport = memo(function HomeUtilitySupport() {
  const {
    t
  } = useTranslation();
  const data = [{
    icon: <YinYangIcon className="h-10 w-10" />,
    title: t("home.See the age to build a house"),
    to: "https://batdongsan.com.vn/ho-tro-tien-ich/ht-xem-tuoi-xay-nha"
  }, {
    icon: <HouseIcon className="h-10 w-10" />,
    title: t("home.Cost of making a house"),
    to: "https://batdongsan.com.vn/ho-tro-tien-ich/ht-du-toan-chi-tiet"
  }, {
    icon: <CalculatorIcon className="h-10 w-10" />,
    title: t("home.Calculate interest rate"),
    to: "https://batdongsan.com.vn/ho-tro-tien-ich/ht-tinh-lai-suat"
  }, {
    icon: <SunIcon className="h-10 w-10" />,
    title: t("home.Feng shui consultation"),
    to: "https://batdongsan.com.vn/ho-tro-tien-ich/ht-xem-huong-nha"
  }];
  return <>
      <TitleHome>{t("home.Utility support")}</TitleHome>
      <div className="mt-4 grid grid-cols-1 gap-8 lg:grid-cols-4">
        {data.map(item => <div key={item.title} className="relative flex items-center justify-center space-x-3 rounded-md border border-gray-300 bg-white px-6 py-4 shadow-sm focus-within:ring-2 focus-within:ring-violet-500 focus-within:ring-offset-2 hover:border-gray-400">
            <div className="flex-shrink-0">{item.icon}</div>
            <div className="min-w-0 flex-1">
              <Link href={item.to} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-semibold text-gray-900 lg:text-base">
                  {item.title}
                </p>
              </Link>
            </div>
          </div>)}
      </div>
    </>;
});
export default HomeUtilitySupport;