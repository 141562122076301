import type { FC } from "react";
type Props = {
  className: string;
};
const YinYangIcon: FC<Props> = ({
  className
}) => {
  return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M15.6025 7.67417L21.1774 12.3414L21.6664 20.6918C21.6522 21.4355 21.0105 22.33 19.7454 23.1175C18.514 23.884 17.0173 24.3334 15.9569 24.3334C11.3826 24.3334 7.66663 20.6068 7.66663 16C7.66663 11.5128 11.1922 7.86073 15.6025 7.67417Z" stroke="#0FB9BF" strokeWidth="2" />
      <path d="M29 23L23 29M19 30H12.5M9 29L3 23M2 12.5V19.5M3 9L9 3M12.5 2H19M23 3L29 9M30 12.5V19.5" stroke="#54D6DA" strokeWidth="1.7" strokeLinecap="round" />
      <path d="M16.6419 20.7012C16.6419 20.1285 16.1555 19.6642 15.5555 19.6642C14.9555 19.6642 14.469 20.1285 14.469 20.7012C14.469 21.2739 14.9555 21.7382 15.5555 21.7382C16.1555 21.7382 16.6419 21.2739 16.6419 20.7012ZM15.5555 6.66669C12.8556 6.66669 10.6666 8.75597 10.6666 11.3334C10.6666 13.9106 12.8556 16 15.5555 16C18.2555 16 20.4444 18.0893 20.4444 20.6667C20.4444 23.2439 18.2555 25.3334 15.5555 25.3334C20.9557 25.3334 25.3333 21.1547 25.3333 16C25.3333 10.8453 20.9557 6.66669 15.5555 6.66669ZM15.5555 12.4048C14.9554 12.4048 14.4691 11.9405 14.4691 11.3678C14.4691 10.7951 14.9554 10.3307 15.5555 10.3307C16.1555 10.3307 16.6419 10.7951 16.6419 11.3678C16.6419 11.9405 16.1555 12.4048 15.5555 12.4048Z" fill="#0FB9BF" />
    </svg>;
};
export default YinYangIcon;