"use client";

import { memo, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
import { type ApiPaging, type PostSellerType } from "@/types";
import { NavigateRouter } from "@/utils";
import { TitleHome } from "../atoms/TitleHome";
import Link from "next/link";
import HomeNews from "../molecules/HomeNews";
import ButtonOutline from "../atoms/ButtonOutline";
type Props = {
  postEstate: ApiPaging<PostSellerType[]> | null;
};
const HomeNewsForYou = memo(function HomeNewsForYou({
  postEstate
}: Props) {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  return <>
      <div className="flex justify-between">
        <TitleHome>{t("home.Real Estate For You")}</TitleHome>
        <div className="hidden md:grid md:grid-cols-2 md:items-center md:divide-x md:divide-solid md:divide-yellow-600">
          <Link href={NavigateRouter.REAL_ESTATE_FOR_SALE} className="mr-4 text-right text-sm text-violet-900 hover:text-violet-700">
            {t("home.News Buy")}
          </Link>
          <Link href={NavigateRouter.REAL_ESTATE_FOR_RENT} className="pl-4 text-left text-sm text-violet-900 hover:text-violet-700">
            {t("home.News Sell")}
          </Link>
        </div>
        <div className="hidden divide-y lg:flex lg:flex-row lg:items-center lg:gap-4"></div>
      </div>
      <div>
        <div className="my-4">
          {postEstate?.total_item_count as number > 0 ? <>
              <div className="grid grid-flow-row gap-2 sm:grid-cols-2 lg:grid-cols-4 lg:gap-8">
                {postEstate?.items.map((item, index) => index <= 7 ? <div key={item.id}>
                      <HomeNews post={item} />
                    </div> : isLoadMore && <div key={item.id}>
                        <HomeNews post={item} />
                      </div>)}
              </div>
              <div className="mt-4 flex justify-center lg:mt-8">
                {isLoadMore ? <ButtonOutline onClick={() => router.push(NavigateRouter.REAL_ESTATE_FOR_SALE)}>
                    {t("common.See more")}
                  </ButtonOutline> : <ButtonOutline onClick={() => setIsLoadMore(true)}>
                    {t("common.Load more")}
                    <ChevronDownIcon className="ml-1 h-4 w-4" />
                  </ButtonOutline>}
              </div>
            </> : <div className="text-center">
              <p className="text-lg font-medium text-gray-700">
                {t("seller.There are currently no postings.")}
              </p>
            </div>}
        </div>
      </div>
    </>;
});
export default HomeNewsForYou;